<template>
  <div class="common-audio">
    <audio
      ref="audio"
      :src="nowmusic.link"
      :controls="true"
      @timeupdate="updateProgress"
      @ended="end"
      @loadedmetadata="loadedmetadata"
      @play="audioPlay(true)"
      @pause="audioPlay(false)"
      :autoplay="isauto"
      style="display: none"
      preload="metadata"
    ></audio>
    <!--自定义样式【电脑端】-->
    <div class="audioBox">
      <el-image :src="nowmusic.pic"></el-image>
      <!--歌曲顶部控件-->
      <div class="audio_header" :style="{'background':'url(' + $http + '/img/videoBottomBack.png)'}">
        <!-- 按钮操作 -->
        <div class="btn_play">
          <img v-if="!isPlay" @click="audioPlay(true)" :src="$http + '/img/playVideo.png'" alt="">
          <img v-else @click="audioPlay(false)" alt="" :src="$http + '/img/stopVideo.png'">
          <!-- <i
            ref="control"
            v-if="!isPlay"
            alt=""
            @click="audioPlay(true)"
            class="el-icon-video-play"
          />
          <i
            ref="control"
            v-else
            alt=""
            @click="audioPlay(false)"
            class="el-icon-video-pause"
          /> -->
        </div>
        <!-- 进度条 -->
        <el-slider
          @change="progressChange"
          :format-tooltip="realFormatSecond"
          v-model="current"
          :max="duration"
          status="success"
        >
        </el-slider>
        <!-- 时间 -->
        <div class="audioInfoTime">
          <div class="opentime">
            <span>{{ this.transTime(current) }}</span>
          </div>
          <span>/</span>
          <div class="endtime">
            <span>{{ this.transTime(duration) }}</span>
          </div>
        </div>
      </div>
      <div class="audio-name">
        {{ nowmusic.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * link: this.musicList[this.musicIndex] // 音频地址
     * name: "电影音乐片段试听2", // 音频名称
     *  pic: "", // 音频头像
     */
    nowmusic: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // 当前播放任务
      audio: null,
      contorl: null,
      // 当前播放时刻
      current: 0,
      // 播放总时长
      duration: 0,
      // 是否正在播放
      isPlay: false,
      // 是否自动播放
      isauto: false,
      // 当前进度条位置
      cacheCurrent: 0,
    };
  },
  mounted() {
    this.audio = this.$refs.audio;
    this.contorl = this.$refs.contorl;
  },
  methods: {
    // 音频时间格式化显示
    transTime(value) {
      let that = this;
      var time = "";
      var h = parseInt(`${value / 3600}`);
      value %= 3600;
      var m = parseInt(`${value / 60}`);
      var s = parseInt(`${value % 60}`);
      if (h > 0) {
        time = that.formatTime(h + ":" + m + ":" + s);
      } else {
        time = that.formatTime(m + ":" + s);
      }
      return time;
    },
    // 歌曲歌手名长度处理
    transName(name) {
      if (name !== undefined) {
        if (name.length > 12) {
          name = name.substring(0, 11) + "...";
        }
      }
      return name;
    },
    // 补零
    formatTime(value) {
      var time = "";
      var s = value.split(":");
      var i = 0;
      for (; i < s.length - 1; i++) {
        time += s[i].length === 1 ? "0" + s[i] : s[i];
        time += ":";
      }
      time += s[i].length === 1 ? "0" + s[i] : s[i];

      return time;
    },
    // 音频播放暂停
    audioPlay(status) {
      if (status) {
        this.audio.play();
        this.isauto = true;
      } else {
        this.audio.pause();
      }
      this.isPlay = status;
    },
    // 更新进度条与当前播放时间
    updateProgress(e) {
      this.current = e.target.currentTime;
    },
    // 拖动进度滚动条
    progressChange() {
      this.$refs.audio.currentTime = this.cacheCurrent;
      this.current = this.cacheCurrent;
    },
    // 实时返回当前进度位置
    realFormatSecond(second) {
      this.cacheCurrent = second;
      return this.transTime(second);
    },
    // 音频结束
    end() {
      this.isPlay = false;
    },
    // 获取音频总时长
    loadedmetadata(e) {
      this.duration = e.target.duration;
    },
  },
};
</script>

<style scoped lang='scss'>
/*电脑版*/
.audioBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.audio_header {
  width: 100%;
  height: 55px;
  line-height: 55px;
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  // background: linear-gradient(rgba(255,255,255,.6), rgba(255,255,255,.4));;
  // backdrop-filter: blur(10px);
  // -webkit-backdrop-filter: blur(10px); 
  // backdrop-filter: blur(10px); /* This is the fallback for Safari that doesn't support backdrop-filter */
}
.el-slider {
  display: flex;
  width: 500px;
  max-width: 527px;
  height: 55px;
  align-items: center;
}
::v-deep .el-slider__button{
  border: none;
}
::v-deep .el-slider__runway{
  height: 2px;
}
::v-deep .el-slider__button-wrapper{
  width: 32px;
  height: 32px;
}
::v-deep .el-slider__bar{
  height: 2px;
  background: transparent;
}
.audioInfoTime {
  width: 130px;
  height: 55px;
  line-height: 55px;
  text-align: right;
  font-size: 16px;
  color: #fff;
  padding-right: 12px;
  box-sizing: border-box;
}
.el-image {
  width: 100%;
  height: 180px;
}
.btn_play {
  width: 40px;
  font-size: 28px;
  height: 55px;
  line-height: 58px;
  margin: 0 6px 0 12px;
  cursor: pointer;
  color: #fff;
  img{
    margin-top: 15px;
    margin-left: 6px;
    width: 24px;
    height: 24px;
  }
}
.opentime {
  height: 20px;
  display: inline-block;
}
.endtime {
  height: 20px;
  display: inline-block;
}
.audio-name{
    margin-top: 6px;
    height: 33px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 33px;
}
</style>